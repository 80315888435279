<template>
  <div id="about" class="bg-site-third p-8 pr-72 md:pr-8">
    <h1 class="font-header text-3xl">About Me</h1>
    <div class="flex mt-4 md:flex-col">
      <div class="w-1/6 mr-6 md:w-1/2 md:mx-auto md:mb-6">
        <img class="rounded-full" src="@/assets/melissa.jpg" />
      </div>
      <div class="w-5/6">
        <p>A 2021 math and computer science graduate from the University of Guelph who welcomes problem solving along with challenges in programming and has a dedication for emerging technology. 7 years programming experience, working primarily in C and Python with experience in Java, Javascript, Lua and SQL. I hope to use my quick problem solving skills, deterministic work effort and skills gained as a computer science and math student to become a valuable member of your team.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

