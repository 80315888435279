<template>
  <div id="education" class="bg-site-third p-8 pr-72 md:pr-8">
    <h1 class="font-header text-3xl">Education</h1>
    <h2 class="font-semibold text-xl mt-4">Univeristy of Guelph</h2>
    <div>
      <p class="text-lg">
        <span class="italic">Bachelor Of Science, Honours</span> | September 2016- April 2021
      </p>

      <div class="mt-2">
        <p>• BSc Mathematical Science, Honours</p>
        <p>• Computer Science Emphasis</p>
        <p>• Statistics Stream</p>
        <p>• Guelph Women in Computer Science (GWICS) – support and engage in the community of women in computer science</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Education"
};
</script>

