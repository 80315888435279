<template>
  <div class="bg-main-bg relative bg-cover w-full h-screen">
    <div class="flex flex-col absolute top-0 left-0 w-full h-screen">
      <div class="my-auto h-64 p-12 text-white">
        <h1 class="text-8xl font-header md:text-6xl">Melissa Kennedy</h1>
        <p class="text-2xl my-4">Software Developer • Toronto, Ontario</p>
        <div class="space-x-8 text-3xl">
          <a
            href="tel:14169932329"
            class="cursor-pointer transition duration-800 ease-in-out hover:text-site-accent"
          >
            <i class="fas fa-phone"></i>
          </a>
          <a
            href="mailto:melissa.4.kennedy@gmail.com"
            class="cursor-pointer transition duration-800 ease-in-out hover:text-site-accent"
          >
            <i class="fas fa-envelope"></i>
          </a>
          <a
            href="https://github.com/Melissa-Kennedy"
            class="cursor-pointer transition duration-800 ease-in-out hover:text-site-accent"
          >
            <i class="fab fa-github"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/melissajkennedy/"
            class="cursor-pointer transition duration-800 ease-in-out hover:text-site-accent"
          >
            <i class="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage"
};
</script>




