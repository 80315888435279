<template>
  <div id="experience" class="bg-site-third p-8 pr-72 md:pr-8">
    <h1 class="font-header text-3xl">Experience</h1>

    <h2 class="font-semibold text-xl mt-4">Wiz Kid Coding</h2>
    <div>
      <p class="text-lg">
        <span class="italic">Coding Instructor</span> | Sept 2020 - Current
      </p>
      <div class="mt-2">
        <p>• Taught lessons in Lua, Python, and Scratch</p>
        <p>• Planned curriculum centred around fundamentals of programming and game design</p>
        <p>• Adapted classes to be inclusive of all age groups</p>
      </div>
    </div>

    <h2 class="font-semibold text-xl mt-4">STEM Camp</h2>
    <div>
      <p class="text-lg">
        <span class="italic">Camp Counselor</span> | June 2019 - September 2019
      </p>
      <div class="mt-2">
        <p>• Lead lessons in computer science, mathematics and science</p>
        <p>• Taught groups of 40 children aged 5-13 years</p>
        <p>• Adapted lessons to fit different levels of learning</p>
      </div>
    </div>

    <h2 class="font-semibold text-xl mt-4">Longos</h2>
    <div>
      <p class="text-lg">
        <span class="italic">Cashier</span> | February 2015-2019
      </p>
      <div class="mt-2">
        <p>• Provided quick and accurate service to customers</p>
        <p>• General cashier duties involving handling money, making change and ensuring proper accountability for payments</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Experience"
};
</script>
